<template>
  <WeContainer card="" v-if="ready">
    <WeCard class="sticky-top mb-3">
      <div class="row align-items-center">
        <div class="col">
          <h5 class="m-0">
            <i class="fa fa-percent"></i> {{ $t("supplierReductionAndProfit") }}
          </h5>
        </div>
        <div class="col-auto">
          <div class="row align-items-center">
            <div class="col pl-0">
              <!-- Submit -->
              <WeSubmitButton
                parent-class="text-center"
                v-bind:submit-status="submit"
                v-bind:update="$route.params.id"
                v-bind:permission="checkPermission"
                v-on:submit="submitForm"
              />
              <!-- ./Submit -->
            </div>
          </div>
        </div>
      </div>
    </WeCard>

    <WeCard>
      <div class="row">
        <div class="col-12 col-lg-6">
          <div class="form-group mb-3">
            <label class="custom-label">Tedarikçi Seç</label>
            <v-select
              label="name"
              v-bind:value="form.supplier"
              v-bind:options="supplier.list"
              v-bind:reduce="(opt) => opt.id"
              v-bind:clearable="true"
              v-on:input="onInput"
              class="setting-select"
            >
            </v-select>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="form-group mb-3">
            <label class="custom-label">Tip Seç</label>
            <v-select
              label="name"
              v-bind:value="form.reduc_type"
              v-bind:options="supplier.reduction_types"
              v-bind:reduce="(opt) => opt.id"
              v-bind:clearable="true"
              class="setting-select"
              v-on:input="onReductType"
              :disabled="supplierStatus == 0 || isLoadingReduct"
            >
            </v-select>
          </div>
        </div>
      </div>
    </WeCard>
    <WeCard class="mt-3" v-if="supplierStatus == 4">
      <WeCard class="alert-primary" v-if="!isXmlReady">
        <span>
          <i class="fas fa-spinner fa-spin"></i> Veriler çekiliyor lütfen
          bekleyiniz...
        </span>
      </WeCard>
      <div class="row" v-else>
        <div class="col-12 col-lg-4 pad-10-10">
          <label> {{ selectedType.name }} seç </label>

          <WeRowInput
            v-if="selectedSupplier.type == 3"
            form="vPaginatedSelectReduce"
            :label="selectedType.name + ' seç'"
            labelClass="display-none"
            inputClass="col-12 col-lg-12"
            :placeholder="selectedType.name + ' seç'"
            v-bind:close-on-select="false"
            v-bind:multiple="true"
            v-bind:option-prop="filterLoadedData"
            v-bind:has-prev-page="hasPrevPage('brand')"
            v-bind:has-next-page="hasNextPage('brand')"
            v-on:goAllSelect="onAllSelectBrand"
            v-on:goPrevPage="onPrevPageBrand"
            v-on:goNextPage="onNextPageBrand"
            v-bind:value="selectedData"
            v-on:input="onSelectData"
          />
          <v-select
            v-else
            label="name"
            size="sm"
            inputClass="col-12 col-lg-12"
            labelClass="display-none"
            :placeholder="selectedType.name + ' seç'"
            v-bind:multiple="true"
            v-bind:options="filterLoadedData"
            v-bind:close-on-select="false"
            v-bind:value="selectedData"
            v-on:input="onSelectData"
          />
        </div>
        <div class="col-12 col-lg-3 pad-10-10">
          <label> İskonto Oranı Gir </label>
          <WeInput
            inputClass="col-12 col-lg-12"
            name="category"
            labelClass="display-none"
            v-bind:prepend="'%'"
            v-bind:required="true"
            placeholder="İskonto Oranı %"
            v-model="form.reduction_rate"
          />
        </div>
        <div class="col-12 col-lg-3 pad-10-10">
          <label> Kâr Oranı Gir </label>
          <WeInput
            inputClass="col-12 col-lg-12"
            name="category"
            labelClass="display-none"
            v-bind:prepend="'%'"
            v-bind:required="true"
            placeholder="Kâr Oranı %"
            v-model="form.profit_rate"
          />
        </div>
        <div class="col-12 col-lg-2 text-center">
          <label></label>
          <button
            type="submit"
            class="btn btn-success"
            style="margin-top: 2.5rem"
            v-on:click="saveReduction"
          >
            <i class="fa fa-plus"></i>
            Ekle
          </button>
        </div>
      </div>
    </WeCard>

    <WeCard
      class="mt-3"
      v-if="!submit && form.reductions.length > 0 && isXmlReady"
    >
      <div class="row">
        <div class="col-12 col-lg-12">
          <WeRowInput
            size="sm"
            inputClass="col-12 col-lg-3 ml-auto"
            labelClass="display-none"
            placeholder="Ara..."
            v-bind:value="searchText"
            v-on:input="searchName($event)"
          />
        </div>
      </div>
      <table class="mt-2 table border straight table-hover">
        <thead class="thead-light">
          <tr>
            <th width="auto" class="sortable">
              <span>{{ selectedType.name }}</span>
            </th>
            <th width="20%" class="sortable">
              <span>İskonto Oranı</span>
            </th>
            <th width="20%" class="sortable">
              <span>Kar Oranı</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in showLoadedData"
            v-bind:key="index"
            v-bind:index="index"
          >
            <td class="text-left">
              {{ item.name }}
            </td>
            <td class="text-left">
              <WeInput
                inputClass="col-12 col-lg-12"
                name="category"
                labelClass="display-none"
                v-bind:prepend="'%'"
                v-bind:required="true"
                placeholder="İskonto Oranı %"
                v-model="item.reduction_rate"
              />
            </td>
            <td class="text-left">
              <WeInput
                inputClass="col-12 col-lg-12"
                name="category"
                v-bind:prepend="'%'"
                v-bind:required="true"
                labelClass="display-none"
                placeholder="Kar Oranı %"
                v-model="item.profit_rate"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <div class="col-12 col-md-auto">
        <div class="row justify-content-end">
          <div class="col-12 col-md-auto">
            <ul class="pagination">
              <li
                class="page-item"
                v-on:click="goToPage(paginate.currentPage - 1)"
              >
                <span class="page-link"
                  ><i class="fas fa-chevron-left"></i
                ></span>
              </li>
              <li
                :class="
                  'page-item ' +
                  (paginate.currentPage == item.page ? 'active' : '')
                "
                v-for="(item, index) in paginate.pageLinks"
                v-bind:key="index"
                v-on:click="goToPage(item.page)"
              >
                <a class="page-link">{{ item.page }}</a>
              </li>
              <li
                class="page-item"
                v-on:click="goToPage(paginate.currentPage + 1)"
              >
                <span class="page-link"
                  ><i class="fas fa-chevron-right"></i
                ></span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </WeCard>
    <WeLoading v-if="submit" />
  </WeContainer>
  <WeLoading v-else />
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  name: "Detail",
  data() {
    return {
      ready: false,
      isLoadingReduct: false,
      isXmlReady: true,
      searchText: "",
      submit: false,
      loadedData: [],
      baseReductionData: [],
      filterLoadedData: [],
      showLoadedData: [],
      paginate: {
        pageCount: 0,
        minPer: 15,
        currentPage: 1,
        pageLinks: [],
      },
      filterPaginate: {
        brand: {
          item_count: 1,
          current_page: 1,
          total_page: 1,
          total_items: 1,
        },
      },
      isUpdate: false,
      supplierStatus: 0,
      selectedSupplier: null,
      selectedData: null,
      selectedType: null,
      form: {
        name: null,
        is_active: true,
        supplier: null,
        reductions: [],
        reduc_type: null,
        reduction_rate: null,
        profit_rate: null,
      },
      tmpLang: null,
    };
  },
  validations: {
    form: {
      name: {
        required,
      },
    },
  },
  methods: {
    ...mapActions("supplier", [
      "create",
      "update",
      "getList",
      "loadXmlCategory",
      "loadXmlBrand",
      "saveReductAndProfit",
      "getSupplierReduction",
      "saveCategories",
      "getBrandTypeList",
      "loadJsonBrand",
    ]),
    ...mapMutations("shared", ["setMessage", "clearMessage"]),
    validateForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$swal({
          title: "Hata",
          text: "Lütfen zorunlu alanları doldurun",
          icon: "error",
        });

        return false;
      } else {
        return true;
      }
    },
    submitForm() {
      if (!this.selectedSupplier) {
        return;
      }
      this.submit = true;
      let saveData = [];
      this.form.reductions.forEach(element => {
        if(!this.baseReductionData.find(A => JSON.stringify(A) === JSON.stringify(element))) {
          saveData.push(element)
        }
      });
      this.updateOrCreate(saveData);
    },
    hasPrevPage(el) {
      const elem = this.filterPaginate[el];
      if (elem) {
        return elem.current_page !== 1;
      } else {
        return false;
      }
    },
    hasNextPage(el) {
      const elem = this.filterPaginate[el];
      if (elem) {
        return elem.current_page < elem.total_page;
      } else {
        return false;
      }
    },
    onPrevPageBrand() {
      const brandFilter = this.filterPaginate.brand;
      const isPrevAvailable = this.hasPrevPage("brand");

      if (isPrevAvailable) {
        this.loadJsonBrandData(
          "BrandType_" + this.selectedType.id,
          brandFilter.current_page - 1
        );
        this.selectedData = null;
      }
    },
    onNextPageBrand() {
      const brandFilter = this.filterPaginate.brand;
      const isNextAvailable = this.hasNextPage("brand");

      if (isNextAvailable) {
        this.loadJsonBrandData(
          "BrandType_" + this.selectedType.id,
          brandFilter.current_page + 1
        );
        this.selectedData = null;
      }
    },
    searchName(text) {
      let result = [];
      if (text == "" || text == null) result = this.form.reductions;
      else {
        result = this.form.reductions.map((value) => {
          return value.name.toLowerCase().indexOf(text.toLowerCase()) != -1
            ? value
            : undefined;
        });
      }
      this.paginate.currentPage = 1;
      let data = result.filter((A) => A !== undefined);
      this.loadFormData(data);
      this.setPageCount(data);
      this.setPageLink();
    },
    onAllSelectBrand() {
      this.selectedData = this.filterLoadedData;
    },
    showConfirmation(cancelButtonText, id) {
      this.$swal({
        title: "İşlem Başarılı",
        text: "Tedarikçi Entegrasyonları listesine dönebilir veya bu sayfada kalabilirsiniz.",
        icon: "success",
        showCancelButton: true,
        cancelButtonText: cancelButtonText,
        confirmButtonText: "Liste Sayfasına Dön",
      }).then((confirm) => {
        if (confirm.isConfirmed) {
          this.redirectToList();
        } else {
          if (id) {
            this.$router.push(`detail/${id}`);
          } else {
            window.location.reload();
          }
        }
      });
    },
    goToPage(page) {
      if (page <= 0) {
        page = 1;
      } else if (page > this.paginate.pageCount) {
        page = this.paginate.pageCount;
      }
      this.paginate.currentPage = page;
      this.loadFormData(this.form.reductions);
      this.setPageLink();
    },
    setPageCount(data) {
      let mod = data.length % this.paginate.minPer;
      let pageCount = data.length / this.paginate.minPer + (mod > 0 ? 1 : 0);
      this.paginate.pageCount = Math.floor(pageCount);
      this.setPageLink();
    },
    setPageLink() {
      this.paginate.pageLinks = helper.paginateLinks(
        this.paginate.pageCount,
        this.paginate.currentPage
      );
    },
    loadFormData(data) {
      let first = (this.paginate.currentPage - 1) * this.paginate.minPer;
      let last = first + this.paginate.minPer - 1;
      last = last > data.length ? data.length : last;
      let datas = data.map((value, index) => {
        if (index >= first && index <= last) {
          return value;
        }
      });
      this.showLoadedData = datas.filter((A) => A !== undefined);
    },
    clearForm() {
      this.form.reduction_rate = null;
      this.form.profit_rate = null;
      this.selectedData = null;
    },
    saveReduction() {
      let saveForm = [];
      for (let element of this.selectedData) {
        let item = {
          type_id: element.id,
          type_name: this.selectedType.uniqKey ?? this.selectedType.key,
          reduction_rate: this.form.reduction_rate ?? 0,
          profit_rate: this.form.profit_rate ?? 0,
          name: element.name,
        };
        this.form.reductions.push(item);
        saveForm.push(item);
      }
      this.setPageCount(this.form.reductions);
      this.updateOrCreate(saveForm);
    },
    onSelectData(payload) {
      this.selectedData = payload;
    },
    updateOrCreate(reductions) {
      this.submit = true;
      this.saveReductAndProfit({
        reductions,
        onSuccess: (result) => {
          this.clearForm();
          this.$swal({
            title: "Başarılı",
            text: "Kaydetme işlemi başarılı",
            icon: "success",
          });
        },
        onFinish: () => {
          this.submit = false;
          this.filterData();
          this.clearForm();
          this.goToPage(1);
          if (
            this.selectedSupplier.type == 3 &&
            this.filterLoadedData.length <= 0
          ) {
            this.loadJsonBrandData(
              "BrandType_" + this.selectedType.id,
              this.filterPaginate.brand.current_page + 1
            );
          }
        },
      });
    },
    sendSwalError(message) {
      this.$swal({
        title: "Hata",
        text: message,
        icon: "error",
      });
    },
    getSupplierTemplate(key) {
      let result = null;
      let supplier = this.supplier.list.find((A) => A.id == this.form.supplier);
      this.selectedSupplier = supplier;
      if (supplier) {
        if (supplier.template === null) {
          this.supplierStatus = 1;
        } else {
          if (supplier.template.template) {
            let temp = JSON.parse(supplier.template.template);
            if (temp) {
              let data = temp.find((A) => A.key == key);
              if (data) {
                if (data.data.length > 0) {
                  result = data;
                  this.supplierStatus = 4;
                } else {
                  this.sendSwalError(
                    this.selectedType.name +
                      " için XML şablonunda eşleştirme yapılmamış."
                  );
                  this.supplierStatus = 3;
                  this.isXmlReady = false;
                  this.isLoadingReduct = false;
                }
              } else {
                this.sendSwalError(
                  this.selectedType.name + " bir XML şablonu alanı bulunmadı!"
                );
                this.isLoadingReduct = false;
              }
            } else {
              this.sendSwalError("Tedarikçiye ait bir XML şablonu bulunamadı!");
              this.isLoadingReduct = false;
            }
          }
        }
      }
      return result;
    },
    onReductType(payload) {
      this.isLoadingReduct = true;
      this.form.reduc_type = payload;
      let type = this.supplier.reduction_types.find((A) => A.id == payload);
      this.selectedType = type;
      this.getSupplierReduction({
        typeName: this.selectedType.uniqKey ?? this.selectedType.key,
        onSuccess: (result) => {
          this.form.reductions = result.data;
          helper.copy(result.data, this.baseReductionData);
          this.setPageCount(this.form.reductions);
          this.goToPage(1);
          switch (type.key) {
            case "category":
              let temp = this.getSupplierTemplate("Category_name");
              if (temp) {
                this.loadXmlCategoryData(temp.data, temp.brace);
              }
              break;
            case "brand":
              let brand = this.getSupplierTemplate("Brand_name");
              if (brand) this.loadXmlBrandData(brand);
              break;
            case "brandType":
              let supplier = this.supplier.list.find(
                (A) => A.id == this.form.supplier
              );
              this.selectedSupplier = supplier;
              if (this.selectedSupplier.type != 3) {
                let brandType = this.getSupplierTemplate(
                  "BrandType_" + type.id
                );
                if (brandType) this.loadXmlBrandData(brandType);
              } else {
                this.supplierStatus = 4;
                this.loadJsonBrandData("BrandType_" + this.selectedType.id);
              }

              break;
            default:
              this.isLoadingReduct = false;
              break;
          }
        },
      });
    },
    onInput(value) {
      this.form.supplier = value;
      this.supplierStatus = 1;
      this.selectedType = null;
      this.form.reduc_type = null;
      this.isXmlReady = false;
      this.loadedData = [];
    },
    filterData() {
      let arr = [];
      for (let item of this.loadedData) {
        if (!this.form.reductions.find((A) => A.type_id == item.id)) {
          arr.push(item);
        }
      }
      this.filterLoadedData = arr;
    },
    loadXmlCategoryData(attributeKey, brace) {
      this.isXmlReady = false;
      this.loadedData = [];
      this.loadXmlCategory({
        address: this.selectedSupplier.address,
        attributeKey: attributeKey,
        supplierId: this.selectedSupplier.id,
        brace,
        onSuccess: (result) => {
          if (result.data.status == "error") {
            this.$swal({
              title: "Hata",
              text: result.data.message,
              icon: "error",
            });
          } else {
            this.loadedData = result.data.data;
            this.filterData();
          }
          this.isLoadingReduct = false;
          this.isXmlReady = true;
        },
      });
    },
    loadXmlBrandData(attributes) {
      let arr = [];
      arr.push(attributes);
      this.isXmlReady = false;
      this.loadedData = [];
      this.loadXmlBrand({
        address: this.selectedSupplier.address,
        attributes: arr,
        onSuccess: (result) => {
          if (result.data.status == "error") {
            this.$swal({
              title: "Hata",
              text: result.data.message,
              icon: "error",
            });
          } else {
            this.loadedData = result.data.data;
            this.filterData();
          }
          this.isLoadingReduct = false;
          this.isXmlReady = true;
        },
      });
    },

    loadJsonBrandData(arr, current_page = 1) {
      const brandFilter = this.filterPaginate.brand;
      brandFilter.current_page = current_page;
      this.isXmlReady = false;
      this.loadedData = [];
      this.loadJsonBrand({
        name: this.selectedSupplier.name,
        filter: brandFilter,
        supplier_id: this.selectedSupplier.id,
        attributes: arr,
        onSuccess: (result) => {
          if (result.data.status == "error") {
            this.$swal({
              title: "Hata",
              text: result.data.message,
              icon: "error",
            });
          } else {
            helper.copy(result.data, brandFilter, ["data", "status"]);
            this.loadedData = result.data.data;
            this.filterData();
          }
          this.isLoadingReduct = false;
          this.isXmlReady = true;
        },
      });
    },
  },
  computed: {
    ...mapState(["supplier", "shared", "session"]),
    submitButtonText() {
      return this.$route.params.id ? "Güncelle" : "Kaydet";
    },
    checkPermission() {
      return permission.check("brand", "u");
    },
  },
  async mounted() {
    this.supplier.reduction_types = [];
    this.getList({
      is_filter: ["template", this.supplier.defaultFilter],
      onSuccess: (result) => {
        this.getBrandTypeList({
          onSuccess: (res) => {
            this.supplier.reduction_types.push(
              { id: 1, name: "Kategori", key: "category" },
              { id: 2, name: "Marka", key: "brand" }
            );
            res.data.items.forEach((item) => {
              this.supplier.reduction_types.push({
                id: item.id,
                name: item.name,
                key: "brandType",
                uniqKey: "brandType_" + item.id,
              });
            });
            this.ready = true;
          },
        });
      },
    });
  },
};
</script>
<style>
.pagination li:hover {
  cursor: pointer;
}
.pad-10-10 {
  padding: 10px 10px;
}
.margin-0 {
  margin-right: 0px;
  margin-left: 0px;
}
.vs__dropdown-menu {
  overflow: scroll !important;
}
</style>